body,
html {
  margin: 0;
  font-family: "Raleway", "Montserrat", sans-serif, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "EB Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 1.2em;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
footer {
  background: #002366;
  color: #fff;
  text-align: center;
  line-height: 4rem;
}
footer > a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
footer > a:visited {
  color: #fff;
}
@media screen and (max-width: 400px) {
  footer {
    font-size: 0.75rem;
    line-height: 2rem;
  }
}
